@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --primary-color: #041B47;
  --primary-color-hover: rgba(4,27,27,.9);
  --body-font-family: 'Poppins', 'sans-serif';
}

body {
  font-family: var(--body-font-family);
}