@import "~d-react-components/dist/dcomponent.es.scss";

.d-table-awesome-component__select-column {
  div {
    &:nth-child(2) {
      display: none;
    }
  }
}

.d-input-search__outline,
.d-input-text__input-container-outline,
.d-select__select-outline {
  border: 1px solid #d9d9d9!important;
}

.show-scroll-bar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}